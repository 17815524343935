<template>
    <div>
        <CV />
    </div>
</template>

<script>
import CV from './components/Home.vue';

export default {
    components: {
        CV
    }
};
</script>
