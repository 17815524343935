<template>
    <body>
        <div class="container main">
            <!-- ////////////////////////////////////////////////////// LEFT COLUMN ////////////////////////////////////////////////////// -->
            <div class="col-lg-3 personalData">
                <h1>
                    {{ data.header.firstName }} <strong>{{ data.header.lastName }}</strong>
                </h1>
                <h3>
                    <strong>{{ data.header.motto }}</strong>
                </h3>

                <!-- ///////// Personal Image ///////// -->
                <img src="../assets/personal.jpg" class="img-thumbnail" alt="Personal Photo" />

                <!-- ///////// Social Accounts Links ///////// -->
                <div class="text-center socialIcons">
                    <a href="https://github.com/Elgayar/" target="_blank"><i class="fab fa-github"></i></a>
                    <a href="https://www.linkedin.com/in/mostafaelgayar/" target="_blank"
                        ><i class="fab fa-linkedin"></i
                    ></a>
                    <a href="mailto:mostafa.elgayar@rwth-aachen.de"><i class="fas fa-envelope"></i></a>
                </div>
                <div class="contact">
                    <i class="fas fa-map-marker-alt"></i><span> {{ data.sidebar.location }}</span
                    ><br />
                </div>
                <hr />

                <h2>{{ data.sidebar.languages.title }}</h2>
                {{ data.sidebar.languages.arabic }}: <strong>{{ data.sidebar.languages.levels.native }}</strong
                ><br />
                {{ data.sidebar.languages.english }}: <strong>{{ data.sidebar.languages.levels.fluent }}</strong
                ><br />
                {{ data.sidebar.languages.german }}: <strong>{{ data.sidebar.languages.levels.intermediate }}</strong
                ><br />
                {{ data.sidebar.languages.french }}: <strong>{{ data.sidebar.languages.levels.beginner }}</strong
                ><br />

                <hr />

                <h2>{{ data.sidebar.technologies.title }}</h2>
                <div class="text-center techs">
                    <span><i class="fab fa-vuejs"></i>VueJS</span>
                    <span><i class="fab fa-react"></i>React</span>
                    <span><i class="fab fa-angular"></i>Angular</span>
                    <span><i class="fab fa-html5"></i>HTML</span>
                    <span><i class="fab fa-css3"></i>CSS</span>
                    <span><i class="fab fa-js-square"></i>Javascript</span>
                    <span><i class="fab fa-node-js"></i>Node JS</span>
                    <span><i class="fab fa-python"></i>Python</span>
                    <span><i class="fab fa-git"></i>Git</span>
                    <span><i class="fas fa-server"></i>Firebase/Firestore</span>
                </div>

                <hr />

                <h2>{{ data.sidebar.interests.title }}</h2>
                <div class="text-center interests">
                    <span><i class="fas fa-headphones"></i> {{ data.sidebar.interests.music }}</span>
                    <span><i class="fas fa-basketball-ball"></i> {{ data.sidebar.interests.basketball }}</span>
                    <span><i class="fas fa-tv"></i> {{ data.sidebar.interests.movies }}</span>
                    <span><i class="fas fa-plane"></i> {{ data.sidebar.interests.travelling }}</span>
                    <span><i class="fas fa-book-open"></i> {{ data.sidebar.interests.reading }}</span>
                    <span><i class="fas fa-camera-retro"></i> {{ data.sidebar.interests.photography }}</span>
                </div>
            </div>
            <!-- ////////////////////////////////////////////////////// END LEFT COLUMN //////////////////////////////////////////////////////-->

            <!-- ////////////////////////////////////////////////////// RIGHT COLUMN (MAIN CV DATA) ////////////////////////////////////////////////////// -->
            <div class="col-lg-9 mainData">
                <h1>
                    {{ data.header.firstName }} <strong>{{ data.header.lastName }}</strong>
                </h1>
                <h3>
                    <strong>{{ data.header.motto }}</strong>
                </h3>
                <p>{{ data.header.bio }}</p>

                <br /><br />

                <!-- Start Left Side of Right Column -->
                <div class="col-lg-6">
                    <!-- Start CV Section -->
                    <div class="cv-section">
                        <h2><i class="fas fa-graduation-cap"></i> {{ data.education.title }}</h2>

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <div class="itemData">
                                <a href="https://www.hpi.de/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.education.masters.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.education.masters.title }}</h5>
                            </div>
                        </div>

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <div class="itemData">
                                <a href="https://www.rwth-aachen.de/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.education.uni.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.education.uni.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <div class="itemData">
                                <a href="https://www.isc.edu.eg/ebis/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.education.school.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.education.school.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->
                        <hr />
                    </div>
                    <!-- End CV Section -->

                    <!-- Start CV Section -->
                    <div class="cv-section">
                        <h2><i class="fab fa-angellist"></i> {{ data.extracurriculars.title }}</h2>

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.start.time }}</span>
                            <div class="itemData">
                                <a href="https://www.starthack.eu/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.start.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.start.title }}</h5>
                                <p>
                                    {{ data.extracurriculars.start.desc1 }}
                                    <a
                                        href="https://debatr.eu/"
                                        style="text-decoration: dotted; color:cornflowerblue;"
                                        target="_blank"
                                        >{{ data.extracurriculars.start.desc2 }}</a
                                    >
                                    {{ data.extracurriculars.start.desc3 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.tqic.time }}</span>
                            <div class="itemData">
                                <a href="https://techquartier.com/impact_challenge/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.tqic.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.tqic.title }}</h5>
                                <p>
                                    {{ data.extracurriculars.tqic.desc1 }}
                                    <a
                                        href="https://www.youtube.com/watch?v=fLuQlPOjtn4"
                                        style="text-decoration: dotted; color:cornflowerblue;"
                                        target="_blank"
                                        >{{ data.extracurriculars.tqic.desc2 }}</a
                                    >
                                    {{ data.extracurriculars.tqic.desc3 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.tqtt.time }}</span>
                            <div class="itemData">
                                <a href="https://techquartier.com/techtalents/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.tqtt.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.tqtt.title }}</h5>
                                <p>
                                    {{ data.extracurriculars.tqtt.desc1 }}
                                    <a
                                        href="https://vesgualize.com"
                                        style="text-decoration: dotted; color:cornflowerblue;"
                                        target="_blank"
                                        >{{ data.extracurriculars.tqtt.desc2 }}</a
                                    >
                                    {{ data.extracurriculars.tqtt.desc3 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.ideahack.time }}</span>
                            <div class="itemData">
                                <a href="https://www.businessmeetstech.de/ideahack" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.ideahack.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.ideahack.title }}</h5>
                                <p>
                                    {{ data.extracurriculars.ideahack.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.tedx.time }}</span>
                            <div class="itemData">
                                <a href="https://www.facebook.com/TEDxYouthEBIS/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.tedx.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.tedx.title }}</h5>
                                <p>
                                    {{ data.extracurriculars.tedx.desc1 }}
                                </p>
                                <ul>
                                    <li>{{ data.extracurriculars.tedx.desc2.point1 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point2 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point3 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point4 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point5 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point6 }}</li>
                                    <li>{{ data.extracurriculars.tedx.desc2.point7 }}</li>
                                </ul>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.google.time }}</span>
                            <div class="itemData">
                                <a href="https://codein.withgoogle.com/archive/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.google.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.google.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.eoi.time }}</span>
                            <div class="itemData">
                                <a href="https://www.facebook.com/eoi.eg/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.eoi.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.eoi.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.extracurriculars.fll.time }}</span>
                            <div class="itemData">
                                <a href="http://www.fllegypt.org/FLL.html" target="_blank"
                                    ><h4>
                                        <strong>{{ data.extracurriculars.fll.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.extracurriculars.fll.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->
                    </div>
                    <!-- End CV Section -->
                </div>
                <!-- End Left Side of Right Column -->

                <!-- Start Right Side of Right Column -->
                <div class="col-lg-6">
                    <!-- Start CV Section -->
                    <div class="cv-section">
                        <h2><i class="fas fa-briefcase"></i> {{ data.experience.title }}</h2>
                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.arcual.time }}</span>
                            <div class="itemData">
                                <a href="https://www.arcual.art" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.arcual.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.arcual.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.join.time }}</span>
                            <div class="itemData">
                                <a href="https://www.join.com" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.join.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.join.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.futury.time }}</span>
                            <div class="itemData">
                                <a href="https://www.futury.eu/themission/greenenergy" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.futury.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.futury.title }}</h5>
                                <p>
                                    {{ data.experience.futury.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.db.time }}</span>
                            <div class="itemData">
                                <a
                                    href="https://www.deutsche-boerse.com/dbg-en/our-company/insights-people-ideas/Inter-n-view-Anthea-Hohmann-Mostafa-Elgayar-2655246"
                                    target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.db.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.db.title }}</h5>
                                <p>
                                    {{ data.experience.db.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.sidestream.time }}</span>
                            <div class="itemData">
                                <a href="https://sidestream.tech" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.sidestream.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.sidestream.title }}</h5>
                                <p>
                                    {{ data.experience.sidestream.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.windflower.time }}</span>
                            <div class="itemData">
                                <a href="https://www.energy.rwth-aachen.de/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.windflower.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.windflower.title }}</h5>
                                <p>{{ data.experience.windflower.desc1 }}</p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.tinkercubes.time }}</span>
                            <div class="itemData">
                                <a href="https://www.linkedin.com/company/tinkercubes-llc/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.tinkercubes.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.tinkercubes.title }}</h5>
                                <p>
                                    {{ data.experience.tinkercubes.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->
                    </div>
                    <!-- End CV Section -->

                    <!-- Start CV Section -->
                    <div class="cv-section">
                        <h2><i class="fas fa-lightbulb"></i> {{ data.sideProjects.title }}</h2>
                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.evenius.time }}</span>
                            <div class="itemData">
                                <a href="https://www.youtube.com/watch?v=EyNU0OLh4fA" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.evenius.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.evenius.title }}</h5>
                                <p>
                                    {{ data.experience.evenius.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.ig.time }}</span>
                            <div class="itemData">
                                <a href="https://ig.academy/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.ig.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.ig.title }}</h5>
                                <p>{{ data.experience.ig.desc1 }}</p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.clinichubb.time }}</span>
                            <div class="itemData">
                                <a href="http://clinichubb.herokuapp.com/" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.clinichubb.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.clinichubb.title }}</h5>
                                <p>
                                    {{ data.experience.clinichubb.desc1 }}
                                </p>
                            </div>
                        </div>
                        <!-- End CV Item -->

                        <div class="cv-item">
                            <!-- Start CV Item -->
                            <i class="fas fa-square-full"></i>
                            <span>{{ data.experience.freelancing.time }}</span>
                            <div class="itemData">
                                <a href="https://www.fiverr.com/gegeneo" target="_blank"
                                    ><h4>
                                        <strong>{{ data.experience.freelancing.name }}</strong>
                                    </h4></a
                                >
                                <h5>{{ data.experience.freelancing.title }}</h5>
                            </div>
                        </div>
                        <!-- End CV Item -->
                    </div>
                    <!-- End CV Section -->
                </div>
                <!-- End Right Side of Right Column -->
            </div>
            <!-- ////////////////////////////////////////////////////// END RIGHT COLUMN (MAIN CV DATA) //////////////////////////////////////////////////////-->
        </div>

        <!-- ///////// Footer - Copyrights ///////// -->
        <h4 class="copyright">© 2022 Mostafa Elgayar</h4>
    </body>
</template>

<script>
import english from '../i18n/en.json';
export default {
    name: 'CV',
    data() {
        return {
            data: english
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

html,
body {
    background-color: #dcdde1;
    font-family: 'Quicksand', sans-serif;
}

body {
    padding: 100px 0;
}

.container {
    padding: 0;
}

.main {
    -webkit-box-shadow: 0px 0px 50px #192a56;
    box-shadow: 0px 0px 50px #192a56;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 900px) {
    .main {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) {
    .personalData h1,
    .personalData h3 {
        display: block !important;
    }
    .mainData h1,
    .mainData h3 {
        display: none;
    }
}
.personalData {
    padding: 40px;
    background-color: #132555;
    color: #f5f6fa;
}

.personalData img {
    height: 210px;
    width: 200px;
    display: block;
    margin: auto;
}

.personalData h1 {
    font-size: 50px;
    text-transform: uppercase;
    display: none;
    text-align: center;
}

.personalData h2 {
    font-size: 25px;
}

.personalData h3 {
    display: none;
    text-align: center;
    margin-bottom: 30px;
}

.personalData i {
    color: #f5f6fa;
}

.socialIcons {
    padding: 10px;
    font-size: 25px;
}

.contact {
    text-align: center;
}

.contact i {
    margin-right: 10px;
}

.contact a {
    text-decoration: none;
    color: #f5f6fa;
}

.progress {
    height: 10px;
}

.progress div {
    height: 100%;
    background-color: #487eb0;
}

.techs i {
    font-size: 40px;
    padding: 15px;
    display: block;
}

.techs span {
    display: inline-block;
}

.interests i {
    font-size: 40px;
    padding: 25px;
    display: block;
}

.interests span {
    display: inline-block;
}

.mainData {
    padding: 50px;
    background-color: #f5f6fa;
}

.mainData h1 {
    font-size: 70px;
    text-transform: uppercase;
}

.mainData h2 {
    margin-bottom: 20px;
    text-transform: uppercase;
}

.cv-section {
    margin-bottom: 50px;
}

.cv-section span,
i {
    padding-left: 10px;
}

.cv-section h2 i {
    padding-left: 3px;
    color: #132555;
}

.cv-section hr {
    border-color: #132555;
}

.cv-item a {
    text-decoration: none;
    color: #132555;
}

.itemData {
    margin: 10px 0 10px 15px;
    border-left: thick solid #132555;
    padding: 10px 0 10px 20px;
}

.copyright {
    color: #fff;
    padding: 5px 0;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
}
</style>
